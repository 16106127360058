/* eslint-disable class-methods-use-this */
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import { createContext, useContext, useCallback } from 'react';
import {
    getProfile,
    login,
    updateProfile as updateProfileRequest,
    changePassword,
    forgotPassword,
    resetPassword,
    refreshToken as refreshTokenRequest,
    getVerificationMail,
    verifyMail,
    sendPasswordReset,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
} from '../Api/AuthAgent';
import { setBearerToken } from '../Common/HttpClient/HttpClient';
import { decodeJwtToken } from '../Common/Encryption/Encryption';
import { success, warning, error } from '../Common/Toast/Toast';
import i18n from '../Config/Localization/i18n';
import { getAuth, onAuthStateChanged, signOut, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { auth, db } from '../Config/firebase';
import { doc, getDoc, collection, query, where, setDoc, updateDoc, Timestamp } from 'firebase/firestore';

class AuthStore {
    authUser = {};

    token = undefined;

    refreshToken = null;

    navigate = null;

    intendedUrl = null;

    authUserData = {};

    constructor() {
        makeObservable(this, {
            authUser: observable,
            authUserData: observable,
            userAuthenticated: computed,
            // token: observable,
            // refreshToken: observable,
            logout: action,
            login: action,
            setUserEmailVerified: action,
            setAuthUser: action,
            isUserAuthenticated: action,
            getCurrentUser: action,
            isUser: computed,
            isAdmin: computed,
            isSuperAdmin: computed,
            authUserIsNotLoaded: computed,
        });
        // eslint-disable-next-line no-use-before-define
        // reaction(() => this.authUser,    .bind(this));
        // reaction(
        //     () => this.refreshToken,
        //     (refreshToken) => saveRefreshTokenToLocalStorage(refreshToken),
        // );

        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in
                await this.getUserDocumentByUserId(user.uid);
                this.setAuthUser(user);
                // console.log('authUserData:', this.authUserData);
            } else {
                this.logout();
            }
        });
        // this.autoLogin();
    }

    logout() {
        this.authUser = null;
        this.authUserData = null;
        signOut(auth);
        // removeTokenToLocalStorage();
        // removeRefreshTokenToLocalStorage();
        // setBearerToken(null);
    }

    get userAuthenticated() {
        return !!this.authUser;
    }

    async getUserDocumentByUserId(userId) {
        try {
            return await getDoc(doc(db, 'users', userId)).then((userDocument) => {
                if (userDocument.exists()) {
                    this.authUserData = userDocument.data();
                    return userDocument.data();
                }
                // console.log('No such document!');
                return null;
            });
        } catch (error) {
            // console.log(error);
            return null;
        }
    }

    async signUp(data, navigate) {
        await registerWithEmailAndPassword(data)
            .then((userCredentials) => {
                this.isUserAuthenticated().then(() => {
                    if (userCredentials) {
                        const user = userCredentials.user;
                        try {
                            const docRef = doc(db, 'users', user.uid);

                            setDoc(doc(db, 'users', user.uid), {
                                uid: user.uid,
                                firstName: data.firstName,
                                lastName: data.lastName,
                                country: data.country,
                                dateOfBirth: data.dateOfBirth,
                                authProvider: 'local',
                                email: data.email,
                                interests: [],
                            })
                                .then(() => {
                                    navigate('/interests');
                                })
                                .catch(() => {
                                    error('Κάτι πήγε στραβά κατά την εγγραφή σας. Δοκιμάστε πάλι αργότερα.');
                                });

                            // // ----- Uncomment if EmailVerification needed after signup-----
                            // if (user != null) {
                            //     sendEmailVerification(auth.currentUser).then(() => {
                            //         success(i18n.t('emailVerification.EmailVerificationMsg'));
                            //         console.log('Email Verification sent to user', user.email);
                            //     });
                            // }
                        } catch (error) {
                            error('Κάτι πήγε στραβά κατά την εγγραφή σας. Δοκιμάστε πάλι αργότερα.');
                        }
                    }
                });
            })
            .catch((err) => {
                // console.error(err);
                switch (err.code) {
                    case 'auth/email-already-in-use':
                        error(i18n.t('register.EmailInUse'));
                        break;
                    default:
                }
            });
    }

    async login({ email, password }, navigate) {
        try {
            const userCredentials = await logInWithEmailAndPassword({ email, password });
            if (userCredentials) {
                const user = userCredentials.user;
                // this.setAuthUser(user);
                // this.getUserDocumentByUserId(user.uid);
                // this.navigate = navigate;
                // this.intendedUrl = location.state.from;
                navigate('/dashboard');
                success(i18n.t('login.YouHaveSuccessFullyLoggedIn'));
            }
        } catch (e) {
            this.logout();
            error('Το email ή/και ο κωδικός είναι λανθασμένο. ');
        }
    }

    setUserEmailVerified() {
        this.authUser.emailConfirmed = true;
    }

    setAuthUser(user) {
        this.authUser = user;
    }

    // eslint-disable-next-line class-methods-use-this
    updateProfile(data) {
        this.isUserAuthenticated()
            .then(async () => {
                const user = getAuth().currentUser;
                const docRef = doc(db, 'users', user.uid);

                await updateDoc(docRef, {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    dateOfBirth: data.dateOfBirth,
                    country: data.country,
                })
                    .then(() => {
                        this.getUserDocumentByUserId(user.uid);
                        success('Τα δεδομένα σας αποθηκεύθηκαν');
                    })
                    .catch(() => {
                        error('Κάτι πήγε στραβά. Δοκιμάστε πάλι αργότερα');
                    });
            })
            .catch((e) => {
                // console.log('2', e);
            });
    }

    // eslint-disable-next-line class-methods-use-this
    updatePassword(currentPassword, newPassword) {
        const user = getAuth().currentUser;
        const cred = EmailAuthProvider.credential(user.email, currentPassword);
        reauthenticateWithCredential(user, cred);
        changePassword(user, newPassword);
        // .then((response) => {
        //     success(i18n.t('forms.PasswordWasSuccessfullyUpdated'));
        // }) // dispatch toast message
        // .catch((e) => console.log(e));
    }

    getCurrentUser() {
        return getAuth().currentUser;
    }

    // eslint-disable-next-line class-methods-use-this
    forgotPassword(email) {
        return sendPasswordReset(email).then((success) => {
            if (success) {
                success(i18n.t('login.ForgotPasswordSuccess'));
            } else {
                error(i18n.t('login.UserNotFound'));
            }
        });
        // forgotPassword(email)
        //     .then((response) => {
        //         console.log(response);
        //         success('A confirmation email has been sent');
        //     })
        //     .catch((e) => console.log);
    }

    // eslint-disable-next-line class-methods-use-this
    resetPassword(data, token) {
        return resetPassword(data, token)
            .then((response) => {
                // console.log(response);
                success('Ο κωδικός σας άλλαξε με επιτυχία');
            })
            .catch((e) => error('Κάτι πήγε στραβά. Δοκιμάστε πάλι αργότερα'));
    }

    verifyEmail(token) {
        return new Promise((resolve, reject) => {
            verifyMail(token)
                .then((response) => {
                    this.setUserEmailVerified();
                    resolve(response);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    sendVerificationEmail() {
        getVerificationMail()
            .then((response) => {
                console.log(response);
                success('A verification email has been sent');
            })
            .catch((e) => {});
    }

    requestNewToken() {
        refreshTokenRequest(this.token, this.refreshToken)
            .then(({ token, refreshToken, success }) => {
                if (success) {
                    this.refreshToken = refreshToken;
                    this.token = token;
                } else {
                    this.logout();
                }
            })
            .catch((e) => this.logout());
    }

    navigateToIntendedUrl() {
        if (this.navigate && this.intendedUrl) {
            this.navigate(this.intendedUrl);
            this.navigate = null;
            this.intendedUrl = null;
        }
    }

    isUserAuthenticated = () =>
        new Promise((resolve, reject) => {
            const auth = getAuth();
            auth.onAuthStateChanged((user) => {
                if (user === null) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject(false);
                } else {
                    resolve(true);
                }
            });
        });

    get authUserIsNotLoaded() {
        // console.log('!Object.keys(this.authUser).length', !Object.keys(this.authUser).length);
        return !Object.keys(this.authUser).length;
    }

    get isUser() {
        return this.authUser?.roles?.includes('User');
    }

    get isAdmin() {
        return this.authUser?.roles?.includes('Admin');
    }

    get isSuperAdmin() {
        return this.authUser?.roles?.includes('Superadmin');
    }
}

export default new AuthStore();

// export const authStore = new AuthStore();
// export default new AuthStore();
// export const AuthStoreContext = createContext(authStore);
// export const useAuthStore = () => useContext(AuthStoreContext);
