import { action, computed, makeObservable, observable, toJS } from 'mobx';

export default class YoutubeStore {
    watchedVideos = [];

    constructor() {
        makeObservable(this, {
            watchedVideos: observable,
            setWatchedVideos: action,
            allVids: computed,
        });
    }

    get allVids() {
        return this.watchedVideos;
    }

    setWatchedVideos = (watchedVideo, initializeVideo) => {
        if (initializeVideo) {
            this.watchedVideos = [];
        } else if (!this.watchedVideos.includes(watchedVideo) && watchedVideo !== '') {
            this.watchedVideos.push(watchedVideo);
            // console.log('watched:', toJS(this.watchedVideos));
        }
    };
}
