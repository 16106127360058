import { Navigate, Route, Routes } from 'react-router-dom';
import { lazy } from 'react';
import Loadable from '../Components/Theme/Loadable';
import { useStore } from '../Stores/GlobalStore';
import { observer } from 'mobx-react';

const Dashboard = Loadable(lazy(() => import('../Pages/Authenticated/Dashboard')));
const Profile = Loadable(lazy(() => import('../Pages/Authenticated/Profile/Profile')));
const VerifyEmail = Loadable(lazy(() => import('../Pages/Authenticated/Profile/VerifyEmail')));

const Users = Loadable(lazy(() => import('../Pages/Authenticated/Users/UsersList')));
const UsersCreate = Loadable(lazy(() => import('../Pages/Authenticated/Users/CreateUsers')));
const EditUsers = Loadable(lazy(() => import('../Pages/Authenticated/Users/EditUsers')));

const Topics = Loadable(lazy(() => import('../Pages/Authenticated/Topics/Topics')));
const Category = Loadable(lazy(() => import('../Pages/Authenticated/Categories/Category')));
const Sections = Loadable(lazy(() => import('../Pages/Authenticated/Sections/Sections')));
const Interests = Loadable(lazy(() => import('../Pages/Authenticated/Interests/InterestsPage')));
const Questionnaire = Loadable(lazy(() => import('../Pages/Authenticated/Questionnaire/Questionnaire')));
const Information = Loadable(lazy(() => import('../Pages/Authenticated/Information/Information')));

const AuthenticatedRoutes = () => {
    const { authStore } = useStore();

    return (
        <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/category/:categoryId" element={<Category />} />
            <Route exact path="/interests" element={<Interests />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/verify-email/:token" element={<VerifyEmail />} />
            <Route exact path="/topic/:topicId" element={<Topics />} />
            <Route exact path="/section/:sectionId" element={<Sections />} />
            <Route exact path="/questionnaire" element={<Questionnaire />} />
            <Route exact path="/information" element={<Information />} />

            {/* <Route exact path="/interests" element={<Interests />} /> */}

            {/* {authStore.authUserIsNotLoaded && <></>}

            {authStore.authUserIsNotLoaded && (
                <>
                    <Route exact path="/users" element={<Users />} />
                    <Route exact path="/users/create" element={<UsersCreate />} />
                    <Route exact path="/users/:id/edit" element={<EditUsers />} />
                </>
            )} */}

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
export default observer(AuthenticatedRoutes);
