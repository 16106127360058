import { action, makeObservable, observable } from 'mobx';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../Config/firebase';

export default class TopicsStore {
    topics = [];

    constructor() {
        makeObservable(this, {
            topics: observable,
            getTopicsByCategoryId: action,
            getTopicById: action,
            setTopics: action,
        });
    }

    getAllTopics = async () => {
        const topicsRef = collection(db, 'topics');
        const topicsQuery = query(topicsRef);
        const querySnapshot = await getDocs(topicsQuery);
        this.topics = [];
        querySnapshot.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots

            this.setTopics([...this.topics, doc.data()]);
        });

        return this.topics;
    };

    getTopicsByCategoryId = async (categoryId) => {
        const topicsRef = collection(db, 'topics');
        const topicsQuery = query(topicsRef, where('categoryId', '==', categoryId));
        const querySnapshot = await getDocs(topicsQuery);
        let topicsData = [];
        querySnapshot.forEach((doc) => {
            topicsData = [...topicsData, doc.data()];
        });

        return topicsData;
    };

    getTopicById = async (topicId) => {
        const docRef = doc(db, 'topics', topicId);
        const docSnap = await getDoc(docRef);
        let data = [];
        if (docSnap.exists()) {
            data = docSnap.data();
            return data;
        }
        // doc.data() will be undefined in this case
        return null;
    };

    setTopics = (topics) => {
        this.topics = topics;
    };
}

// export const topicsStore = new TopicsStore();
// export default new TopicsStore();
// export const TopicsStoreContext = createContext(topicsStore);
// export const useTopicsStore = () => useContext(TopicsStoreContext);
