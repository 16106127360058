import { createContext, useContext } from 'react';
import AuthStore from './AuthStore';
import CategoriesStore from './CategoriesStore';
import InterestsStore from './InterestsStore';
import SectionsStore from './SectionsStore';
import TopicsStore from './TopicsStore';
import SubsectionsStore from './SubsectionsStore';
import Questionnaire from './QuestionnaireStore';
import YoutubeStore from './YoutubeStore';

export const globalStore = {
    authStore: AuthStore,
    categoriesStore: new CategoriesStore(),
    interestsStore: new InterestsStore(),
    sectionsStore: new SectionsStore(),
    topicsStore: new TopicsStore(),
    subsectionsStore: new SubsectionsStore(),
    questionnaireStore: new Questionnaire(),
    youtubeStore: new YoutubeStore(),
};

export const GlobalStoreContext = createContext(globalStore);

export function useStore() {
    return useContext(GlobalStoreContext);
}
