// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import LanguageSwitcher from './LanguageSwitcher';
import InfoFabButton from 'Components/InfoFabButton';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useThemeStore } from '../../Stores/ThemeStore';
import { observer } from 'mobx-react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const themeStore = useThemeStore();

    function onMenuToggle() {
        // themeStore.toggleMenu();
    }

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box component="span" sx={{ display: { xs: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}
            {/* <LanguageSwitcher /> */}
            <InfoFabButton />
            <ProfileSection />
        </>
    );
};

export default observer(Header);
