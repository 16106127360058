import { getAuth } from 'firebase/auth';

import { getFirestore, query, getDocs, collection, where, addDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyC09imEj8BOKt-pWD6mxHuFIReE2-ATJGQ',
    authDomain: 'digicare-platform.firebaseapp.com',
    projectId: 'digicare-platform',
    storageBucket: 'digicare-platform.appspot.com',
    messagingSenderId: '489460397954',
    appId: '1:489460397954:web:cd7044f42d15b6b4df419b',
    measurementId: 'G-R5D1VCZQRS',
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
