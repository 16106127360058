import { requests } from '../Common/HttpClient/HttpClient';
import { error, success } from '../Common/Toast/Toast';
import { useTranslation } from 'react-i18next';
import {
    GoogleAuthProvider,
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    onAuthStateChanged,
    reauthenticateWithCredential,
    EmailAuthProvider,
    updatePassword,
} from 'firebase/auth';

import { auth, db } from '../Config/firebase';

import { getFirestore, collection, addDoc } from 'firebase/firestore';
import i18n from '../Config/Localization/i18n';

const { post, get, patch } = requests;

const googleProvider = new GoogleAuthProvider();

const logInWithEmailAndPassword = async ({ email, password }) => {
    const userCredentials = await signInWithEmailAndPassword(auth, email, password);
    return userCredentials;
};

const registerWithEmailAndPassword = async ({ firstName, lastName, country, dateOfBirth, email, password }) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential;
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return true;
    } catch (err) {
        return false;
    }
};

const isUserLoggedIn = () => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            return true;
        }
        return false;
    });
};

const reauthenticate = async (currentPassword, newPassword) => {
    const user = getAuth().currentUser;
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    reauthenticateWithCredential(user, cred);
    this.changePassword(newPassword);

    // if (!user) return null;

    // const credential = EmailAuthProvider.credential(user.email, currentPassword);
    // // console.log('user', credential);
    // const authCredential = await reauthenticateWithCredential(user, credential);
    // console.log('re-authCredential', authCredential);
    // return authCredential;
};

// export function getUserProfile() {
//     // eslint-disable-next-line consistent-return
//     onAuthStateChanged(auth, (user) => {
//         if (user) {
//             console.log('get UserProfile', user);
//             return user;
//         }
//     });
// }

export function login(email, password) {
    return post('/api/Auth/login', { email, password });
}

export function resetPassword(data, token) {
    return post('/api/Auth/resetPassword', { ...data, token });
}

export function getProfile() {
    return get('/api/Auth');
}

export function getVerificationMail() {
    return get('/api/Auth/sendVerifiacationMail');
}

export function verifyMail(token) {
    return post('/api/Auth/verifyMail', { token });
}

export function updateProfile(phoneNumber) {
    return patch('/api/Auth', { phoneNumber: `${phoneNumber}` });
}

// export function changePassword(currentPassword, newPassword, confirmNewPassword) {
//     return patch('/api/Auth/updatePassword', { currentPassword, newPassword, confirmNewPassword });
// }

export function changePassword(user, newPassword) {
    updatePassword(user, newPassword)
        .then(() => {
            // Update successful.
            success(i18n.t('forms.PasswordWasSuccessfullyUpdated'));
            console.log('Password updated!');
        })
        .catch((error) => {
            // An error happened.
            console.log('updatePassword error', error);
        });
}

export function forgotPassword(email) {
    return post('/api/Auth/forgotPassword', { email: `${email}` });
}

export function refreshToken(token, refreshToken) {
    return post('/api/Auth/refreshToken', { token, refreshToken });
}

export { logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, isUserLoggedIn };
