import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import Authenticated from './Authenticated';
import Guest from './Guest';
import GuestRoutes from '../Routes/GuestRoutes';
import AuthenticatedRoutes from '../Routes/AuthenticatedRoutes';
import ScrollToTop from 'CustomHooks/ScrollToTop';
import { useStore } from '../Stores/GlobalStore';

const AppContent = () => {
    const { authStore } = useStore();
    const { userAuthenticated } = authStore;

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                {userAuthenticated ? (
                    <Authenticated>
                        <AuthenticatedRoutes />
                    </Authenticated>
                ) : (
                    <Guest>
                        <GuestRoutes />
                    </Guest>
                )}
            </BrowserRouter>
        </>
    );
};

export default observer(AppContent);
