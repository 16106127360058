import { action, makeObservable, observable, toJS } from 'mobx';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../Config/firebase';

export default class SubsectionsStore {
    subsections = [];

    constructor() {
        makeObservable(this, {
            subsections: observable,
            getAllSubsections: action,
        });
    }

    getAllSubsections = async () => {
        const subsectionsRef = collection(db, 'subsections');
        const subsectionsQuery = query(subsectionsRef);
        const querySnapshot = await getDocs(subsectionsQuery);
        this.subsections = [];
        querySnapshot.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots

            this.setSubsections([...this.subsections, doc.data()]);
        });

        return this.subsections;
    };

    getSubsectionsBySectionId = async (sectionId) => {
        const subsectionsRef = collection(db, 'subsections');
        const subsectionsQuery = query(subsectionsRef, where('sectionId', '==', sectionId));
        const querySnapshot = await getDocs(subsectionsQuery);
        let subsectionsData = [];
        querySnapshot.forEach((doc) => {
            subsectionsData = [...subsectionsData, doc.data()];
        });

        return subsectionsData;
    };
}

// export const subsectionsStore = new SubsectionsStore();
// export default new SubsectionsStore();
// export const SubsectionsStoreContext = createContext(subsectionsStore);
// export const useSubsectionsStore = () => useContext(SubsectionsStoreContext);
