import { action, makeObservable, observable, reaction } from 'mobx';
import { createContext, useContext } from 'react';
import i18n from '../Config/Localization/i18n';
import { defaultLanguage } from '../Config/constant';
import { saveLocaleToLocalStorage, getLocaleFromLocalStorage } from '../Common/Storage/LocalStorage';

class ThemeStore {
    isMenuOpen = false;

    activeMenuItem = null;

    locale = null;

    constructor() {
        makeObservable(this, {
            isMenuOpen: observable,
            activeMenuItem: observable,
            locale: observable,
            openMenu: action,
            closeMenu: action,
            toggleMenu: action,
            openMenuItem: action,
            changeLocale: action,
            setInitialLocale: action,
        });

        reaction(
            () => this.locale,
            (newLocale) => {
                saveLocaleToLocalStorage(newLocale);
                i18n.changeLanguage(newLocale);
            },
        );

        this.setInitialLocale();
    }

    setInitialLocale() {
        const localeStorageLocale = getLocaleFromLocalStorage();
        this.locale = localeStorageLocale ?? defaultLanguage;
    }

    openMenu() {
        this.isMenuOpen = false;
    }

    closeMenu() {
        this.isMenuOpen = false;
    }

    toggleMenu() {
        // this.isMenuOpen = !this.isMenuOpen;
        this.isMenuOpen = false;
    }

    openMenuItem(menuItem) {
        this.activeMenuItem = menuItem;
    }

    isMenuItemOpen(menuItem) {
        return this.activeMenuItem === menuItem;
    }

    isGroupOpen(group) {
        return this.activeMenuItem?.includes(group.id);
    }

    changeLocale(locale) {
        this.locale = locale;
    }
}

export const themeStore = new ThemeStore();
export default new ThemeStore();

export const ThemeStoreContext = createContext(themeStore);
export const ThemeStoreProvider = ({ children, store }) => (
    <ThemeStoreContext.Provider value={store}>{children}</ThemeStoreContext.Provider>
);
export const useThemeStore = () => useContext(ThemeStoreContext);
