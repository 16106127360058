import { createContext, useContext } from 'react';
import { action, makeObservable, observable, toJS, runInAction } from 'mobx';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Config/firebase';

export default class CategoriesStore {
    categories = [];

    constructor() {
        makeObservable(this, {
            categories: observable,
            getAllCategories: action,
            setCategories: action,
        });
    }

    getAllCategories = async () => {
        const categoriesRef = collection(db, 'categories');
        const categoriesQuery = query(categoriesRef);
        const querySnapshot = await getDocs(categoriesQuery);
        this.categories = [];
        querySnapshot.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots

            this.categories = [...this.categories, doc.data()];
        });
        // console.log('categories', toJS(this.categories));
        return this.categories;
    };

    getCategoryById = async (categoryId) => {
        const docRef = doc(db, 'categories', categoryId);
        const docSnap = await getDoc(docRef);
        let data = [];
        if (docSnap.exists()) {
            data = docSnap.data();
            return data;
        }
        return null;
    };

    setCategories = (categories) => {
        this.categories = categories;
    };
}

// export const categoriesStore = new CategoriesStore();
// export default new CategoriesStore();

// export const CategoriesStoreContext = createContext(categoriesStore);
// export const useCategoriesStore = () => useContext(CategoriesStoreContext);
