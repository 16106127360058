import AppContent from './MainAppComponents/AppContent';
import { ThemeProvider } from '@mui/material/styles';

import './App.css';
import theme from './Config/Themes/config';
import './Config/Validation/yup.config';
import ToastContainer from './Common/Toast/ToastContainer';
import { globalStore, GlobalStoreContext } from './Stores/GlobalStore';

function App() {
    return (
        <>
            <GlobalStoreContext.Provider value={globalStore}>
                <ThemeProvider theme={theme}>
                    <AppContent />
                </ThemeProvider>
                <ToastContainer />
            </GlobalStoreContext.Provider>
        </>
    );
}

export default App;
