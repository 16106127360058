// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { menuItems } from '../menu-items';
// import { useAuthStore } from '../../../Stores/AuthStore';
import { useStore } from '../../../Stores/GlobalStore';

import { useMemo } from 'react';
import { observer } from 'mobx-react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { authStore } = useStore();
    const menu = useMemo(() => menuItems(authStore), [authStore.authUser]);

    return (
        <>
            {menu.items?.map((item) =>
                item.type === 'group' ? (
                    <NavGroup key={item.id} item={item} />
                ) : (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                ),
            )}
        </>
    );
};

export default observer(MenuList);
