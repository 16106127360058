import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { createContext, useContext } from 'react';
import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion, addDoc } from 'firebase/firestore';
import AuthStore from './AuthStore';
import { db } from '../Config/firebase';
import { success, error } from '../Common/Toast/Toast';
import i18n from '../Config/Localization/i18n';

export default class QuestionnaireStore {
    constructor() {
        makeObservable(this, {
            saveQuestionnaire: action,
        });
    }

    saveQuestionnaire = async (topicId, radio1Value, radio2Value, radio3Value) => {
        const currentUser = AuthStore.getCurrentUser();
        const questionnaireRef = doc(db, 'users', currentUser.uid, 'questionnaires', topicId);
        return setDoc(
            questionnaireRef,
            {
                questionnaireAnswers: { 0: radio1Value, 1: radio2Value, 2: radio3Value },
            },
            { merge: false },
        )
            .then(() => {
                const msg = 'Ευχαριστούμε για τη συμπλήρωση του ερωτηματολόγιου';
                success(msg);
                return msg;
            })
            .catch((e) => {
                const msg = 'Κάτι πήγε στραβά. Δοκιμάστε πάλι αργότερα';
                error(msg, e);
                return `${msg}${e}`;
            });
    };
}
