import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { collection, getDocs, doc, updateDoc, arrayUnion, deleteField, getDoc, setDoc } from 'firebase/firestore';
import AuthStore from './AuthStore';
import { db } from '../Config/firebase';
import { success, error } from '../Common/Toast/Toast';
import i18n from '../Config/Localization/i18n';

export default class InterestsStore {
    interestsList = null;

    selectedInterests = [];

    isAnswered = false;

    constructor() {
        makeObservable(this, {
            interestsList: observable,
            selectedInterests: observable,
            isAnswered: observable,
            getAllInterests: action,
            toggleSelected: action,
            isSelected: action,
        });

        this.init();
    }

    init() {
        this.getUserInterests();
    }

    // eslint-disable-next-line class-methods-use-this
    getAllInterests = async () => {
        const querySnapshot = await getDocs(collection(db, 'interests'));

        this.interestsList = [];
        // eslint-disable-next-line array-callback-return
        querySnapshot.forEach((doc) => {
            // this.interestsList.push(doc.data());

            this.interestsList = [...this.interestsList, doc.data()];
        });
        // console.log('interests', toJS(this.interestsList));
    };

    // eslint-disable-next-line class-methods-use-this
    getUserInterests = () => {
        AuthStore.isUserAuthenticated().then(async () => {
            const currentUser = AuthStore.getCurrentUser();
            const userRef = doc(db, `users/${currentUser.uid}`);

            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                this.selectedInterests = docSnap.data().interests;
            } else {
                this.selectedInterests = [];
                error('Κάτι πήγε στραβά. Δοκιμάστε πάλι αργότερα.');
            }
        });
    };

    // eslint-disable-next-line class-methods-use-this
    setUserInterests = async () => {
        const currentUser = AuthStore.getCurrentUser();

        const userRef = doc(db, 'users', currentUser?.uid);

        try {
            await updateDoc(userRef, {
                interests: deleteField(),
            });

            await updateDoc(userRef, {
                interests: arrayUnion(...this.selectedInterests),
            })
                .then(() => {
                    success(i18n.t('interests.InterestsSavedSuccessfully'));
                    this.isAnswered = true;
                })
                .catch((err) => {
                    error(err);
                });
        } catch (err) {
            error(err);
        }
    };

    toggleSelected = (interest) => {
        if (!interest) {
            return;
        }
        // eslint-disable-next-line react/no-this-in-sfc
        // const newList = [...this.interestsList];
        const existingItem = this.selectedInterests?.find((item) => item === interest.interestId);
        if (existingItem) {
            this.selectedInterests.splice(this.selectedInterests.indexOf(interest.interestId), 1);
        } else {
            this.selectedInterests.push(interest.interestId);
        }

        // this.selectedInterests = newList;
    };

    isSelected = (interestId) => this.selectedInterests.includes(interestId);
}

// export const interestsStore = new InterestsStore();
// export default new InterestsStore();
// export const InterestsStoreContext = createContext(interestsStore);
// export const useInterestsStore = () => useContext(InterestsStoreContext);
