import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { createContext, useContext } from 'react';
import { collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion, addDoc } from 'firebase/firestore';
import AuthStore from './AuthStore';
import { db } from '../Config/firebase';
import { success, error } from '../Common/Toast/Toast';
import i18n from '../Config/Localization/i18n';

export default class SectionsStore {
    sections = null;

    topicCompleted = null;

    constructor() {
        makeObservable(this, {
            sections: observable,
            topicCompleted: observable,
            getSectionsByTopicId: action,
            getSectionById: action,
            saveUserProgress: action,
            getUsersCourseCompletedById: action,
            checkSectionIsCompleted: action,
        });
    }

    // getAllSections = async () => {
    //     const sectionsRef = collection(db, 'sections');
    //     const sectionsQuery = query(sectionsRef);
    //     const querySnapshot = await getDocs(sectionsQuery);
    //     this.sections = [];
    //     querySnapshot.forEach(async (doc) => {
    //         // doc.data() is never undefined for query doc snapshots

    //         this.setSections([...this.sections, doc.data()]);
    //     });

    //     return this.sections;
    // };

    getSectionById = async (sectionId) => {
        const docRef = doc(db, 'sections', sectionId);
        const docSnap = await getDoc(docRef);
        let data = [];
        if (docSnap.exists()) {
            data = docSnap.data();
            return data;
        }
        // doc.data() will be undefined in this case
        return null;
    };

    getSectionsByTopicId = async (topicId) => {
        const sectionRef = collection(db, 'sections');
        const sectionsQuery = query(sectionRef, where('topicId', '==', topicId));
        const querySnapshot = await getDocs(sectionsQuery);
        let sectionsData = [];
        querySnapshot.forEach((doc) => {
            sectionsData = [...sectionsData, doc.data()];
        });

        return sectionsData;
    };

    saveUserProgress = async (section) => {
        const currentUser = AuthStore.getCurrentUser();
        const userRef = doc(db, 'users', currentUser.uid, 'coursesCompleted', section.topicId);

        return setDoc(
            userRef,
            {
                sectionsCompleted: arrayUnion(section.sectionId),
            },
            { merge: true },
        )
            .then((resp) => {
                const msg = resp;
                return msg;
            })
            .catch((e) => {
                const msg = 'Κάτι πήγε στραβά. Δοκιμάστε πάλι αργότερα';
                error(msg, e);
                return `${msg}${e}`;
            });
    };

    getUsersCourseCompletedById = async (topicId) => {
        if (!topicId) return;
        const currentUser = AuthStore.getCurrentUser();
        const docSnap = await getDoc(doc(db, `users/${currentUser.uid}/coursesCompleted/${topicId}`));

        // const q = query(collection(db, 'users', currentUser.uid, 'coursesCompleted'), where('id', '==', topicId));
        // const querySnapshot = await getDocs(q);
        let sectionsCompleted = [];
        // querySnapshot.forEach(async (doc) => {
        //     // doc.data() is never undefined for query doc snapshots
        //     console.log(doc.id, ' => ', doc.data());
        //     this.topicsCompleted = [...this.topicsCompleted, doc.data()];
        // });

        if (docSnap.exists()) {
            sectionsCompleted = docSnap.data();
            // console.log('sections Completed data:', JSON.stringify(docSnap.data()));
        }
        // eslint-disable-next-line consistent-return
        return sectionsCompleted;
    };

    // getTopicById = async (topicId) => {
    //     const docRef = doc(db, 'topics', topicId);
    //     const docSnap = await getDoc(docRef);
    //     let data = [];
    //     if (docSnap.exists()) {
    //         data = docSnap.data();
    //         return data;
    //     }
    //     // doc.data() will be undefined in this case
    //     return null;
    // };

    checkSectionIsCompleted = async (topicId, sectionId) => {
        if (!sectionId) return;
        const currentUser = AuthStore.getCurrentUser();
        const docSnap = await getDoc(doc(db, `users/${currentUser.uid}/coursesCompleted/${topicId}`));

        let sectionsCompleted = [];
        // querySnapshot.forEach(async (doc) => {
        //     // doc.data() is never undefined for query doc snapshots
        //     console.log(doc.id, ' => ', doc.data());
        //     this.topicsCompleted = [...this.topicsCompleted, doc.data()];
        // });

        if (docSnap.exists()) {
            sectionsCompleted = docSnap.data().sectionsCompleted;
        }
        // eslint-disable-next-line consistent-return
        return sectionsCompleted?.includes(sectionId);
    };
}

// export const sectionsStore = new SectionsStore();
// export default new SectionsStore();
// export const SectionsContext = createContext(sectionsStore);
// export const useSectionsStore = () => useContext(SectionsContext);
