import { Fab, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useThemeStore } from '../Stores/ThemeStore';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import InfoIcon from '@mui/icons-material/Info';

const InfoFabButton = () => {
    const { t } = useTranslation();
    const themeStore = useThemeStore();
    const navigate = useNavigate();

    function onInfoClick(event) {
        event.preventDefault();
        navigate(`/information`);
    }

    return (
        <Fab variant="extended" style={{ background: '#2196f3', marginRight: '1rem' }} onClick={(event) => onInfoClick(event)}>
            <Typography sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, fontWeight: 'bold' }} className="text-white text-2xl">
                ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ ΠΛΑΤΦΟΡΜΑΣ
            </Typography>
            <InfoIcon sx={{ color: 'white' }} fontSize="large" />
        </Fab>
    );
};

export default observer(InfoFabButton);
